import React from "react";
import { Container, Typography, Grid, Box } from "@mui/material";

// Import all images
import IMG7981 from "../assets/images/IMG_7981.png";
import IMG7982 from "../assets/images/IMG_7982.png";
import IMG7987 from "../assets/images/IMG_7987.png";
import IMG7988 from "../assets/images/IMG_7988.png";
import IMG7993 from "../assets/images/IMG_7993.png";
import IMG7995 from "../assets/images/IMG_7995.png";
import IMG7996 from "../assets/images/IMG_7996.png";
import IMG7998 from "../assets/images/IMG_7998.png";
import IMG8003 from "../assets/images/IMG_8003.png";
import IMG8004 from "../assets/images/IMG_8004.png";
import IMG8006 from "../assets/images/IMG_8006.png";
import IMG8007 from "../assets/images/IMG_8007.png";
import IMG8008 from "../assets/images/IMG_8008.png";
import IMG8013 from "../assets/images/IMG_8013.png";
import IMG8014 from "../assets/images/IMG_8014.png";
import IMG8016 from "../assets/images/IMG_8016.png";
import IMG8017 from "../assets/images/IMG_8017.png";
import IMG8018 from "../assets/images/IMG_8018.png";
import IMG8019 from "../assets/images/IMG_8019.png";
import IMG8020 from "../assets/images/IMG_8020.png";

function Gallery() {
  const allImages = [
    IMG7981, IMG7982, IMG7987, IMG7988, IMG7993,
    IMG7995, IMG7996, IMG7998, IMG8003, IMG8004,
    IMG8006, IMG8007, IMG8008, IMG8013, IMG8014,
    IMG8016, IMG8017, IMG8018, IMG8019, IMG8020
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography
        variant="h2"
        component="h1"
        sx={{
          color: "#0088e1",
          borderBottom: "2px solid #0088e1",
          pb: 2,
          mb: 4
        }}
      >
        GALLERY
      </Typography>

      <Grid container spacing={3}>
        {allImages.map((image, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                height: 300,
                width: "100%",
                overflow: "hidden",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "scale(1.02)",
                  boxShadow: "0 6px 12px rgba(0,0,0,0.15)"
                }
              }}
            >
              <img
                src={image}
                alt={`Gallery item ${index + 1}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Gallery;
