import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  AppBar,
  Box,
  Container,
  Button,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../assets/images/Blady_s Little World Logo.png";

function Header() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();
  
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleLanguage = () => {
    const newLang = i18n.language === "en" ? "es" : "en";
    i18n.changeLanguage(newLang);
  };

  const menuItems = [
    { title: t("navigation.home"), path: "/" },
    { title: t("navigation.aboutUs"), path: "/about-us" },
    { title: t("navigation.gallery"), path: "/gallery" },
    { title: t("navigation.dietaryNeeds"), path: "/dietary-needs" },
    { title: t("navigation.requestInfo"), path: "/request-info" },
    { title: t("navigation.scheduleTour"), path: "/schedule-tour" },
    { title: t("navigation.getDirections"), path: "/get-directions" }
  ];

  return (
    <AppBar position="sticky" sx={appBarStyle}>
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={toolbarStyle}>
          <Link to="/" style={{ flexGrow: 0 }}>
            <img src={logo} alt="Blady's Little World" style={logoStyle} />
          </Link>

          <Box sx={{ 
            flexGrow: 1, 
            display: { xs: "none", md: "flex" },
            justifyContent: "flex-end",
            gap: 2
          }}>
            {menuItems.map((item) => (
              <Button
                key={item.path}
                component={Link}
                to={item.path}
                sx={{
                  ...navButtonStyle,
                  ...(location.pathname === item.path && activeNavStyle)
                }}
              >
                {item.title}
              </Button>
            ))}
            <Button
              variant="contained"
              onClick={toggleLanguage}
              sx={languageButtonStyle}
            >
              {i18n.language.toUpperCase()}
            </Button>
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="menu"
              onClick={handleMenu}
              sx={{ color: "#333" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={mobileMenuStyle}
            >
              {menuItems.map((item) => (
                <MenuItem 
                  key={item.path}
                  onClick={handleClose}
                  component={Link}
                  to={item.path}
                >
                  <Typography textAlign="center">{item.title}</Typography>
                </MenuItem>
              ))}
              <MenuItem>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={toggleLanguage}
                  sx={languageButtonStyle}
                >
                  {i18n.language.toUpperCase()}
                </Button>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

const appBarStyle = {
  backgroundColor: "white",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  color: "#333"
};

const toolbarStyle = {
  minHeight: "80px",
  justifyContent: "space-between"
};

const logoStyle = {
  height: "60px",
  width: "auto",
  marginRight: "20px"
};

const navButtonStyle = {
  color: "#333",
  textTransform: "none",
  fontSize: "15px",
  "&:hover": {
    backgroundColor: "rgba(0, 136, 225, 0.1)",
    color: "#0088e1"
  }
};

const activeNavStyle = {
  color: "#0088e1",
  backgroundColor: "rgba(0, 136, 225, 0.1)"
};

const languageButtonStyle = {
  backgroundColor: "#0088e1",
  color: "white",
  "&:hover": {
    backgroundColor: "#0077cc"
  }
};

const mobileMenuStyle = {
  "& .MuiPaper-root": {
    width: "100%",
    maxWidth: "300px",
    mt: 1.5
  }
};

export default Header;
