import React from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Paper
} from "@mui/material";

function DietaryNeeds() {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography
          variant="h2"
          component="h1"
          align="center"
          sx={{
            color: "#0088e1",
            borderBottom: "2px solid #0088e1",
            pb: 2,
            mb: 4
          }}
        >
          {t("forms.dietaryNeeds.title")}
        </Typography>
        
        <Box 
          component="form" 
          action="https://formspree.io/f/xzzbzblg"
          method="POST"
          sx={{ 
            display: "flex", 
            flexDirection: "column", 
            gap: 3 
          }}
        >
          <TextField
            required
            name="childName"
            label={t("forms.dietaryNeeds.childName")}
            fullWidth
          />
          
          <TextField
            required
            name="age"
            label={t("forms.dietaryNeeds.age")}
            type="number"
            fullWidth
          />
          
          <TextField
            required
            name="allergies"
            label={t("forms.dietaryNeeds.allergies")}
            multiline
            rows={3}
            fullWidth
          />
          
          <TextField
            name="restrictions"
            label={t("forms.dietaryNeeds.restrictions")}
            multiline
            rows={3}
            fullWidth
          />
          
          <TextField
            name="preferences"
            label={t("forms.dietaryNeeds.preferences")}
            multiline
            rows={3}
            fullWidth
          />
          
          <Button
            type="submit"
            variant="contained"
            size="large"
            sx={{
              mt: 2,
              backgroundColor: "#0088e1",
              "&:hover": {
                backgroundColor: "#0077cc"
              }
            }}
          >
            {t("forms.dietaryNeeds.submit")}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default DietaryNeeds;
