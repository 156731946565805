import React from "react";
import { Box, Container, Typography, Link, Grid } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#0088e1",
        color: "white",
        py: 6,
        mt: "auto"
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Contact Us
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <LocationOnIcon sx={{ mr: 1 }} />
              <Typography>
                84 Warren Ave, Marlborough, MA 01752
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <PhoneIcon sx={{ mr: 1 }} />
              <Link 
                href="tel:+17746230803" 
                sx={{ 
                  color: "white",
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline"
                  }
                }}
              >
                (774) 623-0803
              </Link>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <EmailIcon sx={{ mr: 1 }} />
              <Link 
                href="mailto:bladyslittleworld@gmail.com"
                sx={{ 
                  color: "white",
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline"
                  }
                }}
              >
                bladyslittleworld@gmail.com
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Hours of Operation
            </Typography>
            <Typography sx={{ mb: 1 }}>
              Monday - Friday
            </Typography>
            <Typography sx={{ mb: 1 }}>
              7:00 AM - 5:00 PM
            </Typography>
            <Typography sx={{ fontStyle: "italic" }}>
              Closed on Weekends & Major Holidays
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Follow Us
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Link 
                href="https://www.facebook.com/profile.php?id=100081626233655"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  color: "white",
                  "&:hover": {
                    color: "rgba(255,255,255,0.8)"
                  }
                }}
              >
                <FacebookIcon sx={{ fontSize: 32 }} />
              </Link>
              <Link 
                href="https://www.instagram.com/bladyslittleworld/?hl=es"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  color: "white",
                  "&:hover": {
                    color: "rgba(255,255,255,0.8)"
                  }
                }}
              >
                <InstagramIcon sx={{ fontSize: 32 }} />
              </Link>
            </Box>
          </Grid>
        </Grid>

        <Typography 
          variant="body2" 
          align="center" 
          sx={{ 
            mt: 4,
            pt: 2,
            borderTop: "1px solid rgba(255,255,255,0.2)"
          }}
        >
          © {new Date().getFullYear()} Blady's Little World. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;