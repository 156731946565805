import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Typography, Box, Paper } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

function GetDirections() {
  const { t } = useTranslation();

  useEffect(() => {
    // Load the Google Maps script
    const loadMapScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAreJJMR6vs7b4hwXBYnJIOnHw2MCkJBAE&callback=initMap`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    loadMapScript();

    // Initialize the map
    window.initMap = () => {
      const location = { lat: 42.3456, lng: -71.6081 }; // Replace with your actual coordinates
      const map = new window.google.maps.Map(document.getElementById("map"), {
        zoom: 15,
        center: location,
      });

      new window.google.maps.Marker({
        position: location,
        map: map,
        title: t("common.businessName"), // Marker title
      });
    };
  }, [t]);

  const googleMapsUrl = "https://www.google.com/maps?q=84+Warren+Ave,Marlborough+MA+01752"; // Google Maps URL

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography
        variant="h2"
        component="h1"
        sx={{
          color: "#0088e1",
          borderBottom: "2px solid #0088e1",
          pb: 2,
          mb: 4,
        }}
      >
        {t("getDirections.title")}
      </Typography>

      <Box sx={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
        <Paper sx={{ flex: 1, p: 3, minWidth: "300px" }}>
          <Typography variant="h6" sx={{ mb: 3, color: "#0088e1" }}>
            {t("getDirections.ourLocation")}
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <LocationOnIcon sx={{ mr: 2, color: "#0088e1" }} />
            <Box>
              <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
                {t("common.businessName")}
              </Typography>
              <Typography variant="body1">84 Warren Ave</Typography>
              <Typography variant="body1">Marlborough, MA 01752</Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <PhoneIcon sx={{ mr: 2, color: "#0088e1" }} />
            <Typography variant="body1">
              <a
                href="tel:+17746230803"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  "&:hover": {
                    color: "#0088e1",
                  },
                }}
              >
                {t("common.phone")}
              </a>
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <EmailIcon sx={{ mr: 2, color: "#0088e1" }} />
            <Typography variant="body1">
              <a
                href="mailto:bladyslittleworld@gmail.com"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  "&:hover": {
                    color: "#0088e1",
                  },
                }}
              >
                {t("common.email")}
              </a>
            </Typography>
          </Box>

          <Typography variant="body1" sx={{ mt: 4, color: "#666" }}>
            {t("getDirections.directions")}
          </Typography>
        </Paper>

        <Box sx={{ flex: 2, minWidth: "300px", height: "500px" }}>
          <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
            <div id="map" style={{ height: "100%", borderRadius: "8px" }} />
          </a>
        </Box>
      </Box>
    </Container>
  );
}

export default GetDirections;