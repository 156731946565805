import React from "react";
import { Routes as RouterRoutes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import DietaryNeeds from "./pages/DietaryNeeds";
import RequestInfo from "./pages/RequestInfo";
import ScheduleTour from "./pages/ScheduleTour";
import GetDirections from "./pages/GetDirections";

function Routes() {
  return (
    <RouterRoutes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/dietary-needs" element={<DietaryNeeds />} />
      <Route path="/request-info" element={<RequestInfo />} />
      <Route path="/schedule-tour" element={<ScheduleTour />} />
      <Route path="/get-directions" element={<GetDirections />} />
    </RouterRoutes>
  );
}

export default Routes;
