import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Typography, Button, Box, Grid } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { styled } from "@mui/material/styles";

// Import all images
import IMG7981 from "../assets/images/IMG_7981.png";
import IMG7982 from "../assets/images/IMG_7982.png";
import IMG7987 from "../assets/images/IMG_7987.png";
import IMG7988 from "../assets/images/IMG_7988.png";
import IMG7993 from "../assets/images/IMG_7993.png";
import IMG7995 from "../assets/images/IMG_7995.png";
import IMG7996 from "../assets/images/IMG_7996.png";
import IMG7998 from "../assets/images/IMG_7998.png";
import IMG8003 from "../assets/images/IMG_8003.png";
import IMG8004 from "../assets/images/IMG_8004.png";
import IMG8006 from "../assets/images/IMG_8006.png";
import IMG8007 from "../assets/images/IMG_8007.png";
import IMG8008 from "../assets/images/IMG_8008.png";
import IMG8013 from "../assets/images/IMG_8013.png";
import IMG8014 from "../assets/images/IMG_8014.png";
import IMG8016 from "../assets/images/IMG_8016.png";
import IMG8017 from "../assets/images/IMG_8017.png";
import IMG8018 from "../assets/images/IMG_8018.png";
import IMG8019 from "../assets/images/IMG_8019.png";
import IMG8020 from "../assets/images/IMG_8020.png";

const StyledCarousel = styled(Carousel)(({ theme }) => ({
  "& .MuiPaginationItem-root": {
    color: "#0088e1",
  },
  "& .CarouselItem": {
    padding: theme.spacing(2),
  },
  "& .MuiButtonBase-root": {
    "&.MuiButton-root": {
      minWidth: "8px",
      width: "8px",
      height: "8px",
      padding: 0,
      margin: "0 4px",
      borderRadius: "50%",
      backgroundColor: "rgba(0, 136, 225, 0.3)",
      "&.active": {
        backgroundColor: "#0088e1",
      }
    }
  }
}));

const ImageBox = styled(Box)(({ theme }) => ({
  height: "300px",
  width: "100%",
  overflow: "hidden",
  borderRadius: theme.spacing(1),
  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.02)",
    boxShadow: "0 6px 12px rgba(0,0,0,0.15)",
  },
}));

function Home() {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = React.useState(0);
  
  const allImages = [
    IMG7981, IMG7982, IMG7987, IMG7988, IMG7993,
    IMG7995, IMG7996, IMG7998, IMG8003, IMG8004,
    IMG8006, IMG8007, IMG8008, IMG8013, IMG8014,
    IMG8016, IMG8017, IMG8018, IMG8019, IMG8020
  ];

  const imageGroups = [];
  for (let i = 0; i < allImages.length; i += 3) {
    imageGroups.push(allImages.slice(i, i + 3));
  }

  const handleImageClick = (position) => {
    if (position === "left") {
      setActiveIndex((prev) => 
        prev === 0 ? imageGroups.length - 1 : prev - 1
      );
    } else if (position === "right") {
      setActiveIndex((prev) => 
        prev === imageGroups.length - 1 ? 0 : prev + 1
      );
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography
        variant="h2"
        component="h1"
        align="center"
        sx={{
          color: "#0088e1",
          fontSize: { xs: "32px", md: "48px" },
          mb: 4,
          textTransform: "uppercase"
        }}
      >
        {t("home.title")}
      </Typography>

      <Typography
        variant="h6"
        align="center"
        sx={{
          maxWidth: "800px",
          margin: "0 auto",
          mb: 6,
          color: "#666",
          fontSize: { xs: "16px", md: "18px" },
          lineHeight: 1.8
        }}
      >
        At Blady's Little World, our vision is to be a leader in early childhood education, cultivating a love for
        learning and cultural appreciation, while our mission is to provide a safe, inclusive, and engaging
        bilingual environment that empowers children to explore, learn, and grow into confident, empathetic
        individuals through innovative teaching and strong family partnerships.
      </Typography>

      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography variant="h5" sx={{ mb: 2, color: "#0088e1", fontSize: { xs: "20px", md: "24px" } }}>
          {t("home.operatingHours")}
        </Typography>
        <Typography variant="h6" sx={{ color: "#333" }}>
          {t("home.hours")}
        </Typography>
      </Box>

      <Box sx={{ 
        display: "flex", 
        justifyContent: "center", 
        gap: { xs: 1, md: 2 },
        flexWrap: "wrap",
        mb: 6 
      }}>
        <Button
          component="a"
          href="/dietary-needs"
          variant="contained"
          sx={buttonStyle}
        >
          {t("home.buttons.dietaryNeeds")}
        </Button>
        <Button
          component="a"
          href="/request-info"
          variant="contained"
          sx={buttonStyle}
        >
          {t("home.buttons.requestInfo")}
        </Button>
        <Button
          component="a"
          href="/schedule-tour"
          variant="contained"
          sx={buttonStyle}
        >
          {t("home.buttons.scheduleTour")}
        </Button>
      </Box>

      <StyledCarousel
        index={activeIndex}
        onChange={(index) => setActiveIndex(index)}
        animation="slide"
        autoPlay={true}
        navButtonsAlwaysInvisible={true}
        indicators={true}
        interval={6000}
        swipe={true}
        cycleNavigation={true}
      >
        {imageGroups.map((group, groupIndex) => (
          <Box key={groupIndex} className="CarouselItem">
            <Grid container spacing={2}>
              {group.map((img, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <ImageBox
                    onClick={() => {
                      if (index === 0) handleImageClick("left");
                      if (index === 2) handleImageClick("right");
                    }}
                    sx={{
                      cursor: (index === 0 || index === 2) ? "pointer" : "default",
                      "&:hover": {
                        transform: (index === 0 || index === 2) ? "scale(1.02)" : "none",
                        "& img": {
                          opacity: (index === 0 || index === 2) ? 0.8 : 1
                        }
                      }
                    }}
                  >
                    <img
                      src={img}
                      alt={`Slide ${groupIndex * 3 + index + 1}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        transition: "opacity 0.3s ease"
                      }}
                    />
                  </ImageBox>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </StyledCarousel>
    </Container>
  );
}

const buttonStyle = {
  backgroundColor: "#0088e1",
  color: "white",
  padding: "10px 20px",
  "&:hover": {
    backgroundColor: "#0077cc"
  }
};

export default Home;
