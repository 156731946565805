import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { LanguageProvider } from "./contexts/LanguageContext";
import theme from "./theme";
import "./i18n";
import Routes from "./Routes";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
  return (
    <BrowserRouter>
      <LanguageProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="App">
            <Header />
            <main>
              <Routes />
            </main>
            <Footer />
          </div>
        </ThemeProvider>
      </LanguageProvider>
    </BrowserRouter>
  );
}

export default App;