import React from "react";
import { Container, Typography, Grid, Paper } from "@mui/material";

function About() {
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography
        variant="h2"
        component="h1"
        sx={{
          color: "#0088e1",
          borderBottom: "2px solid #0088e1",
          pb: 2,
          mb: 4
        }}
      >
        ABOUT US
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper sx={{ p: 4, mb: 4 }}>
            <Typography variant="h4" sx={{ color: "#0088e1", mb: 3 }}>
              OUR VISION
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8 }}>
              Our vision at Blady's Little World is to be a recognized leader in early childhood education. 
              We strive to cultivate a love for learning and cultural appreciation, empowering our little 
              ones to become confident, empathetic, and globally-minded individuals.
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.8 }}>
              By nurturing a diverse and supportive environment, we aim to prepare our children to 
              embrace the world with empathy and confidence.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 4, mb: 4 }}>
            <Typography variant="h4" sx={{ color: "#0088e1", mb: 3 }}>
              OUR MISSION
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8 }}>
              Our mission is to provide a quality educational service that promotes a safe, inclusive, 
              and engaging bilingual environment where children can explore, learn, and grow. We are 
              dedicated to fostering language development, cultural appreciation, and social-emotional 
              skills, empowering every child to become a curious, confident, and compassionate individual.
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.8 }}>
              Through innovative teaching practices and strong community partnerships, we aim to inspire 
              a lifelong love for learning in every child. We work closely with families to achieve 
              holistic child development, involving them in the entire educational process.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 4, height: "100%" }}>
            <Typography variant="h4" sx={{ color: "#0088e1", mb: 3 }}>
              MS. BLADYMAR PORRAS
            </Typography>
            <Typography variant="h6" sx={{ color: "#666", mb: 2 }}>
              Lead Teacher
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8 }}>
              Bladymar, the heart behind Blady's Little World, has dedicated her life to creating 
              a loving and engaging environment for children. Certified as a Child Development 
              Associate (CDA), she blends interactive learning with bilingual education to ensure 
              that each child feels valued and nurtured.
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.8 }}>
              With years of experience in early childhood education, Bladymar creates a warm, 
              family-like atmosphere where children can learn, grow, and thrive. Her bilingual 
              approach helps children develop language skills naturally through daily activities 
              and play.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 4, height: "100%" }}>
            <Typography variant="h4" sx={{ color: "#0088e1", mb: 3 }}>
              MS. PAOLA PEDRAZA
            </Typography>
            <Typography variant="h6" sx={{ color: "#666", mb: 2 }}>
              Assistant Teacher
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8 }}>
              Paola's passion for early education, combined with her warm personality, helps 
              children feel comfortable and confident. With 7 years of experience, she specializes 
              in emotional development and interactive play. Every child in Paola's care is given 
              the individual attention they need to flourish.
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.8 }}>
              Her approach focuses on creating engaging activities that promote both learning and 
              fun, ensuring each child's unique needs are met while fostering a love for learning.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default About;
